// const COMMON = "http://localhost:1250/";
// const COMMON = "http://65.1.114.66:1250/";
const COMMON = "https://api.thriftyx.com/";

const routes = Object.freeze({
    USER: COMMON + 'admin/user',
    LOGIN: COMMON + "admin/login",
    EVENT: COMMON + "admin/event",
    ORDER: COMMON + "admin/order",
    GET_EVENT: COMMON + "main/event",
    GALLERY: COMMON + "main/gallery",
    REGISTER: COMMON + "admin/register",
    SUB_ADMIN: COMMON + "admin/sub-admin",
    GALLERY_ADMIN: COMMON + "admin/gallery",
    ORDER_TICKET: COMMON + "admin/order-ticket",
    GET_CONTACT_US: COMMON + "admin/contact-us",
    VERIFY_TOKEN: COMMON + "admin/verify-token",
    UPLOAD_ON_S3: COMMON + "main/upload_on_s3"
});
export default routes;