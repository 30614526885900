import Moment from "moment";
import { useSelector } from 'react-redux';
import { AiFillSafetyCertificate } from "react-icons/ai";
import { CiCalendar } from "react-icons/ci";
import { IoWalletOutline } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { TbHandFinger } from "react-icons/tb";
import { CiBookmark } from "react-icons/ci";
import parse from 'html-react-parser';
import EventCard from "./EventCard";
const months = require("../data/Months.json");
 
const EventDetails = () => {
    const { eventById, eventData } = useSelector((state) => state.event);

    const handleGetDirections = () => {
        const destination = encodeURIComponent(eventById?.venue);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destination}&travelmode=driving`;
                    window.open(googleMapsUrl, '_blank');
                },
                (error) => {
                    console.error('Error obtaining current location', error);
                    alert('Unable to obtain current location.');
                }
            );
        }
        else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    return (
        <section className="top-wrapper">
            <div className="container">
                <div className="event-detail-upper">
                    <div className="row">
                        <div className="col-lg-8">
                            {eventById?.image &&
                                <img src={eventById?.image} alt="Event_Image" />}
                            <div className="event-description-box">
                                <h4>About</h4>
                                <div className="covid-instruction">
                                    <div className="d-flex align-items-center">
                                        <AiFillSafetyCertificate 
                                            color="#6c30ed"
                                            fontSize="24px"
                                        />
                                        <h4>Covid 19 Safety Measures</h4>
                                    </div>
                                    <div className="safety">
                                        This event adheres to the following 
                                        <span>Health & safety measures</span>
                                        <div className="d-flex align-items-center limited-capacity">
                                            <TiTick color="2feda8" />
                                            <span>Limited Capacity</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="event-description">
                                    {eventById?.description && 
                                        parse(eventById?.description)}
                                </div>
                                {eventById?.venue &&
                                    <>
                                        <h4 className="instruction-heading">Venue</h4>
                                        <p className="venue-detail">
                                            <span>{eventById?.venue}</span>
                                        </p>
                                        <button
                                            type="button"
                                            className="btn get-d"
                                            onClick={handleGetDirections}
                                        >
                                            GET DIRECTIONS
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card event-card-detail">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {eventById?.name} ({eventById?.city})
                                    </h5>
                                    <div>
                                        <ul>
                                            <li>
                                                <CiBookmark 
                                                    color="black" 
                                                    fontSize="large"
                                                />
                                                <span>{eventById?.type}</span>
                                            </li>
                                            <li>
                                                <CiCalendar 
                                                    color="black" 
                                                    fontSize="large"
                                                />
                                                <span>
                                                    {months[Moment(eventById?.date).month()].code}{" "}
                                                    {Moment(eventById?.date).date()}{" "}|{" "}
                                                    {Moment(eventById?.date).format('h:mm A')}
                                                </span>
                                            </li>
                                            <li>
                                                <CiCalendar 
                                                    color="black" 
                                                    fontSize="large"
                                                />
                                                <span>{eventById?.venue}, {eventById?.city}</span>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center price-box">
                                                    <IoWalletOutline 
                                                        color="black" 
                                                        fontSize="large"
                                                    />
                                                    <div className="d-flex align-items-center price">
                                                        <FaRupeeSign 
                                                            color="black" 
                                                            fontSize="large"
                                                        />
                                                        <span>
                                                            {eventById?.tickets.reduce((min, obj) => Math.min(min, obj.price), Infinity)}{" "}
                                                            Onwards
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card event-condition-card">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Event Guide
                                    </h5>
                                    <ul>
                                        <li>
                                            <CiCalendar 
                                                color="black" 
                                                fontSize="large"
                                            />
                                            <p>
                                                <span>For Age (x)</span><br/>
                                                <span>{eventById?.min_age}Y - {eventById?.max_age}Y</span>
                                            </p>
                                        </li>
                                        <li>
                                            <CiCalendar 
                                                color="black" 
                                                fontSize="large"
                                            />
                                            <p>
                                                <span>Language</span><br/>
                                                <span>English, Hindi, Hinglish</span>
                                            </p>
                                        </li>
                                        <li>
                                            <CiCalendar 
                                                color="black" 
                                                fontSize="large"
                                            />
                                            <p>
                                                <span>Game</span><br/>
                                                <span>Participate and have fun</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(eventData && eventData.length > 1) && 
                    <div className="suggested-event-box">
                        <h4 className="instruction-heading suggested-events">
                            YOU MAY LOVE THESE TOO 
                            <div className="finger">
                                <TbHandFinger color="#ecd80c" />
                            </div>
                        </h4>
                        {eventData?.map((item, index) => (
                            item?._id !== eventById?._id && 
                            <EventCard 
                                key={index} 
                                item={item} 
                            />
                        ))}
                    </div>
                }
            </div>
        </section>
    )
}
export default EventDetails;