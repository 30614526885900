import { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { RxCross2 } from "react-icons/rx";
import JoditEditor from 'jodit-react';
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useDispatch, useSelector } from 'react-redux';
import uploadOnS3 from '../redux/uploadOnS3';
import Loader from '../assets/loader.gif';
import PageLoader from './PageLoader';
import { removeEmpty } from '../utils/removeEmpty';
import { useNavigate, useLocation } from "react-router-dom";
import { addEvent, updateEvent, getEventById } from '../redux/event/event.slice';
import { 
    GoogleMap, 
    Marker, 
    useJsApiLoader,
    StandaloneSearchBox 
} from '@react-google-maps/api';

const cityList = require("../data/IndiaCity.json");

const useStyles = makeStyles({
    w48: {
        width: '48%',
    },
    ticketRoot: {
        marginBottom: '24px'
    },
    ticket: {
        padding: '8px 12px',
        borderRadius: '4px',
        marginBottom: '16px',
        border: '1px solid #d71ef7',
        position: 'relative'
    },
    btnRemove: {
        top: 2,
        right: 4,
        padding: 0,
        border: 'none',
        height: 'fit-content',
        background: 'none',
        position: 'absolute',
    }
});

const containerStyle = {
    width: '100%',
    height: '400px',
    marginTop: '12px',
    borderRadius: 4,
    border: '1px solid black'
};

const formData = {
    name:"",
    type: "",
    city:"",
    venue:"",
    image:"",
    min_age: 18,
    max_age: 25,
    description:"",
    tickets: [],
    instruction: "",
    start_time: "10:00",
    end_time: "11:00",
    status: "Unpublished",
    cordinates: {
        latitude: 17.491659,
        longitude: 78.391983
    },
    date: new Date().toISOString(),
}
const formDataForTemplate = {
    name:"hellow",
    type: "",
    city:"",
    venue:"",
    image:"",
    min_age: 18,
    max_age: 25,
    description:"",
    tickets: [],
    start_time: "10:00",
    end_time: "11:00",
    status: "Unpublished",
    instruction: "",
    cordinates: {
        latitude: 17.491659,
        longitude: 78.391983
    },
    date: new Date().toISOString(),
}
const places = ["places"];
const center = {
    lat: 17.491659,
    lng: 78.391983
};
const EventForm = ({from}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const editor = useRef(null);
    const inputRef = useRef();
    const searchBoxRef = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [imageLoader, setImageLoader] = useState(false);
    const [cordinates, setCordinates] = useState(center);
    const [duplicateEvent, setDuplicateEvent] = useState(false);

    const { isLoader, eventById } = useSelector((state) => state.event);

    const [eventData, setEventData] = useState(formData);
    const [timeError, setTimeError] = useState(false);
    const [isSumbitClicked, setSumbitClicked] = useState(false);

    useEffect(() => { eventById && setEventData(eventById) }, [eventById]);

    useEffect(() => { 
        location.pathname === "/event/template" && setEventData(formDataForTemplate) 
    }, [location.pathname]);

    const handleChange = async(e) => {
        e.target.name === "name" && setEventData({...eventData, name: e.target.value});
        e.target.name === "type" && setEventData({...eventData, type: e.target.value});
        e.target.name === "city" && setEventData({...eventData, city: e.target.value});
        e.target.name === "min_age" && setEventData({...eventData, min_age: Number(e.target.value)});
        e.target.name === "max_age" && setEventData({...eventData, max_age: Number(e.target.value)});
        e.target.name === "description" && setEventData({...eventData, description: e.target.value});
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    setImageLoader(true);
                    let res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setEventData({...eventData, image: res?.data?.urls[0]})
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleSubmit = () => {
        setSumbitClicked(true);
        if(formValidator().isValid){
            if(from === "edit" && !duplicateEvent){
                let body = {
                    name: eventData.name,
                    type: eventData.type,
                    city: eventData.city,
                    venue: eventData.venue,
                    image: eventData.image,
                    date: eventData.date,
                    start_time: eventData.start_time,
                    end_time: eventData.end_time,
                    min_age: eventData.min_age,
                    max_age: eventData.max_age,
                    cordinates: eventData.cordinates,
                    description: eventData.description,
                    instruction: eventData.instruction,
                    tickets: eventData.tickets,
                    status: eventData.status,
                }
                let newBody = removeEmpty(body);
                const res = dispatch(updateEvent({body: newBody, id: eventData._id}));
                res?.then((r) => {
                    if(r?.payload?.statusCode === 200){
                        dispatch(getEventById({id: eventData._id}))
                        toast.success(r?.payload?.message);
                        return;
                    }
                    toast.error(r?.payload);
                });
                return;
            }
            else{
                let body = {
                    name: eventData.name,
                    type: eventData.type,
                    city: eventData.city,
                    venue: eventData.venue,
                    image: eventData.image,
                    date: eventData.date,
                    min_age: eventData.min_age,
                    max_age: eventData.max_age,
                    start_time: eventData.start_time,
                    end_time: eventData.end_time,
                    cordinates: cordinates,
                    description: eventData.description,
                    instruction: eventData.instruction,
                    tickets: eventData.tickets,
                    status: eventData.status,
                }
                if(duplicateEvent && (body.name === eventById.name) && 
                    (body.start_time === eventById.start_time) &&
                    (body.end_time === eventById.end_time)){
                    toast.error("Duplicate Event Of Same Name, Start Time And End Time Not Allowed");
                    return;
                }
                let newBody = removeEmpty(body);
                const res = dispatch(addEvent(newBody));
                res?.then((r) => {
                    if(r?.payload?.statusCode === 200){
                        setEventData({...formData});
                        toast.success(r?.payload?.message);
                        navigate(-1);
                        return;
                    }
                    toast.error(r?.payload);
                });
                return;
            }
        }
        toast.error(formValidator().message);
    }

    const formValidator = () => {
        let status = {isValid: true, message: ""};
        let isHourSame = false;
        setTimeError(false);
        let startHour = eventData.start_time.split(":")[0];
        let startMinute = eventData.start_time.split(":")[1];
        let endHour = eventData.end_time.split(":")[0];
        let endMinute = eventData.end_time.split(":")[1];
        startHour === endHour && (isHourSame = true)

        if(eventData.name === "" || !eventData.name){
            status.isValid = false;
            status.message = "Please enter event name";
            return status;
        }
        if(eventData.type === "" || !eventData.type ||
            eventData.type === "Please select event type"){
            status.isValid = false;
            status.message = "Select event type";
            return status;
        }
        if(eventData.city === "" || !eventData.city ||
            eventData.city === "Select city"){
            status.isValid = false;
            status.message = "Please select city";
            return status;
        }
        // if(eventData.venue === "" || !eventData.venue){
        //     status.isValid = false;
        //     status.message = "Please enter venue";
        //     return status;
        // }
        if(eventData.min_age === "" || !eventData.min_age){
            status.isValid = false;
            status.message = "Please enter min age";
            return status;
        }
        if(eventData.min_age < 3 || eventData.min_age > 50){
            status.isValid = false;
            status.message = "Min age must be in [4, 49]";
            return status;
        }
        if(eventData.max_age === "" || !eventData.max_age){
            status.isValid = false;
            status.message = "Please enter max limit";
            return status;
        }
        if(eventData.max_age < 3 || eventData.max_age > 50){
            status.isValid = false;
            status.message = "Max age must be in [4, 49]";
            return status;
        }
        if(eventData.min_age  >= eventData.max_age){
            status.isValid = false;
            status.message = "Min age must be less than max age";
            return status;
        }
        if(eventData.image === "" || !eventData.image){
            status.isValid = false;
            status.message = "Please upload 1 image";
            return status;
        }
        if(eventData.description === "" || !eventData.description){
            status.isValid = false;
            status.message = "Please enter description";
            return status;
        }
        if((!isHourSame && (startHour > endHour)) ||
            (isHourSame && (startMinute > endMinute))){
            setTimeError(true);
            status.isValid = false;
            status.message = "End time always greater than start time";
            return status;
        }
        if(eventData.instruction === "" || !eventData.instruction){
            status.isValid = false;
            status.message = "Please enter event instruction";
            return status;
        }
        if(eventData.tickets.length > 0){
            eventData.tickets.map((item) => {
                if(item.name === ""){
                    status.isValid = false;
                    status.message = "Name missing in tickets";
                    return status;
                }
                if(item.price === ""){
                    status.isValid = false;
                    status.message = "Price missing in tickets";
                    return status;
                }
                if(Number(item.quantity) < 1){
                    status.isValid = false;
                    status.message = "Invalid ticket quantity";
                    return status;
                }
                if(Number(item.price) < 0){
                    status.isValid = false;
                    status.message = "Invalid ticket price";
                    return status;
                }
                if(item.description === ""){
                    status.isValid = false;
                    status.message = "Description missing in tickets";
                    return status;
                }
                return status;
            });
            return status;
        }
        return status;
    }

    const handleAddTicket = () => {
        let array = [...eventData.tickets];
        array.push({
            sold: 0,
            name: "",
            price: "",
            quantity: "",
            description: "",
        });
        setEventData({...eventData, tickets: [...array]});
    }

    const removeTicket = (index) => {
        let array = [...eventData.tickets];
        array.splice(index, 1);
        setEventData({...eventData, tickets: [...array]});
    }

    const handleTicketChange = (e, index) => {
        let array = [...eventData.tickets];
        array = array.map((element, i) => {
            return i === index ? 
            {
                ...element,
                ...(e.target.name === `name_${index}` && {name: e.target.value}),
                ...(e.target.name === `price_${index}` && {price: e.target.value}),
                ...(e.target.name === `quantity_${index}` && {quantity: e.target.value}),
                ...(e.target.name === `description_${index}` && {description: e.target.value}),
            } : element
        })
        setEventData({...eventData, tickets: [...array]});
    }

    const handlePlaceChange = () => {
        const places = searchBoxRef.current.getPlaces();
        if(places){
            setCordinates({
                lat: places[0].geometry.location.lat(), 
                lng: places[0].geometry.location.lng()
            })
            setEventData({
                ...eventData,
                cordinates: {
                    latitude: places[0].geometry.location.lat(),
                    longitude: places[0].geometry.location.lng()
                },
                venue: places[0].formatted_address
            })
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAmoAZCGBeetvhQgUB64tvVrMC9sYIUDTw",
        libraries: places
    });

    const onUnmount = useCallback(function callback() {}, []);

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    }, []);

    const richTextEditor = (value, name) => {
        name === "description" && setEventData({...eventData, description: value});
        name === "instruction" && setEventData({...eventData, instruction: value});
    }

    const handleAddressChange = e => setEventData({...eventData, venue: e.target.value});
    const handleStartTimeSelect = time => setEventData({...eventData, start_time: time});
    const handleEndTimeSelect = time => setEventData({...eventData, end_time: time});
    const handleDateSelect = date => setEventData({...eventData, date: date.toISOString()});
    const handleSwitchChange = e => setDuplicateEvent(e.target.checked);

    if(!isLoaded) return ( <div>Loading</div> );
    if(isLoader) return ( <PageLoader />);

    return (
        <div className="event-form-container">
            {eventById && 
                <div className="form-check form-switch btn-duplicate">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        id="flexSwitchCheckDefault" 
                        onChange={handleSwitchChange}
                    />
                    <label 
                        className="form-check-label fw-bold" 
                        for="flexSwitchCheckDefault"
                    >
                        Duplicate Event
                    </label>
                </div>
            }
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    {eventById ? "Event Details" : "Create New Event"}
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <input
                            type="text" 
                            className="form-control"
                            id="name"
                            name="name"
                            value={eventData.name}
                            onChange={handleChange}
                            placeholder="Enter event name" />
                        {(eventData.name === "" && isSumbitClicked) &&
                            <p className="error">
                                Please enter event name
                            </p>
                        }
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="type" className="form-label">
                            Type
                        </label>
                        {/* <input 
                            type="text" 
                            className="form-control" 
                            id="type"
                            name="type"
                            value={eventData.type}
                            onChange={handleChange}
                            placeholder="Enter event type" /> */}
                        <select 
                            id="type"
                            name="type"
                            className="form-select" 
                            value={eventData.type}
                            onChange={handleChange}
                            aria-label="Default select example"
                        >
                            <option>Select event type</option>
                            <option>Games</option>
                            <option>Games and Play</option>
                            <option>Couple Games</option>
                        </select>
                        {((eventData.type === "" ||
                            eventData.type === "Select event type") && 
                            isSumbitClicked) &&
                            <p className="error">
                                Please select event type
                            </p>
                        }
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="city" className="form-label">
                            City
                        </label>
                        <select 
                            id="city"
                            name="city"
                            className="form-select" 
                            value={eventData.city}
                            onChange={handleChange}
                            aria-label="Default select example">
                            <option>Select city</option>
                            {cityList.map((item, index) => (
                                <option value={item.name} key={index}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        {((eventData.city === "" ||
                            eventData.city === "Select city") && 
                            isSumbitClicked) &&
                            <p className="error">
                                Please select city
                            </p>
                        }
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="venue" className="form-label">
                            Venue
                        </label>
                        <StandaloneSearchBox
                            onLoad={ref=> (searchBoxRef.current = ref)}
                            onPlacesChanged={handlePlaceChange}>
                            <input
                                type="text"
                                ref={inputRef}
                                onChange={handleAddressChange}
                                value={eventData.venue}
                                className="form-control"
                                placeholder="Search Location"
                            />
                        </StandaloneSearchBox>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={cordinates}
                            zoom={2}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                                fullscreenControl: false
                            }}>
                            <Marker position={cordinates} />
                        </GoogleMap>
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="age_limit" className="form-label">
                            Age Limit
                        </label>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex w-p-48 align-items-center">
                                <label 
                                    htmlFor="min_age" 
                                    className="form-label mr-8">
                                    Min:
                                </label>
                                <div className="w-100 position-relative">
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="min_age"
                                        name="min_age"
                                        value={eventData.min_age}
                                        onChange={handleChange}
                                        placeholder="Enter min age" />
                                    {((eventData.min_age === "" ||
                                        eventData.min_age < 3 ||
                                        eventData.min_age > 50) && 
                                        isSumbitClicked) &&
                                        <p className="error">
                                            {eventData.min_age === "" && "Please enter min age"}
                                            {(eventData.min_age !== "" &&
                                                (eventData.min_age < 3 || eventData.min_age > 50))
                                                && "Min age must be in [4, 49]"}
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="d-flex w-p-48 align-items-center">
                                <label 
                                    htmlFor="max_age" 
                                    className="form-label mr-8">
                                    Max:
                                </label>
                                <div className="w-100 position-relative">
                                    <input
                                        type="number" 
                                        className="form-control" 
                                        id="max_age"
                                        name="max_age"
                                        value={eventData.max_age}
                                        onChange={handleChange}
                                        placeholder="Enter max age" />
                                    {((eventData.max_age === "" ||
                                        eventData.max_age < 3 ||
                                        eventData.max_age > 50) && 
                                        isSumbitClicked) &&
                                        <p className="error">
                                            {eventData.max_age === "" && "Please enter max age"}
                                            {(eventData.max_age !== "" &&
                                                (eventData.max_age < 3 || eventData.max_age > 50))
                                                && "Max age must be in [4, 49]"}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        {((Number(eventData.min_age) >= Number(eventData.max_age)) && 
                            isSumbitClicked) &&
                            <p className="error">
                                Min age must be less than max age
                            </p>
                        }
                    </div>
                    {/* <div className="mb-3 form-box">
                        <label htmlFor="address" className="form-label">
                            Address
                        </label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="address"
                            name="address"
                            value={eventData.address}
                            onChange={handleChange}
                            placeholder="Enter address" />
                    </div> */}
                    <div className="mb-3 form-box">
                        <label htmlFor="address" className="form-label">
                            Image
                        </label>
                        <input 
                            type="file" 
                            className="form-control" 
                            id="file"
                            name="file"
                            onChange={handleChange} />
                        {((eventData.image === "") && 
                            isSumbitClicked) &&
                            <p className="error">
                                Please upload 1 image
                            </p>
                        }
                        {!imageLoader ?
                            (eventData.image && 
                            <div className="event-image">
                                <img src={eventData.image} alt="event_image" />
                            </div>)
                            :
                            <div className="text-center">
                                <img
                                    width={50}
                                    src={Loader}
                                    alt="loader"
                                    className="mt-3"
                                />
                            </div>
                        }
                    </div>
                    <div className="mb-3 form-box rich-text">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <JoditEditor 
                            ref={editor}
                            value={eventData?.description}
                            onChange={text => richTextEditor(text, "description")}
                        />
                        {(eventData.description === "" &&
                            isSumbitClicked) &&
                            <p className="error">
                                Please enter description
                            </p>
                        }
                    </div>
                    <div className="mb-3 form-box date">
                        <label className="form-label">
                            Date
                        </label>
                        <DatePicker
                            showIcon
                            className='form-control'
                            minDate={new Date()}
                            selected={eventData?.date}
                            onSelect={handleDateSelect} //when day is clicked
                        />
                    </div>
                    <div className="d-flex justify-content-between time position-relative">
                        <div className={`mb-3 form-box ${classes.w48}`}>
                            <label className="form-label">
                                Start Time
                            </label>
                            <TimePicker 
                                clearIcon={null}
                                className="form-control"
                                onChange={handleStartTimeSelect} 
                                value={eventData?.start_time}
                            />
                            {/* <input type="email" className="form-control" id="type" placeholder="Enter event name here" /> */}
                        </div>
                        <div className={`mb-3 form-box time ${classes.w48}`}>
                            <label className="form-label">
                                End Time
                            </label>
                            <TimePicker 
                                clearIcon={null}
                                className="form-control"
                                onChange={handleEndTimeSelect} 
                                value={eventData?.end_time}
                            />
                        </div>
                        {console.log(timeError, isSumbitClicked)}
                        {(timeError && isSumbitClicked) &&
                            <p className="error time-error">
                                End time always greater than start time
                            </p>
                        }
                    </div>
                    <div className="mb-3 form-box rich-text">
                        <label htmlFor="instruction" className="form-label">
                            Event Instruction
                        </label>
                        <JoditEditor 
                            ref={editor}
                            value={eventData?.instruction}
                            onChange={text => richTextEditor(text, "instruction")}
                        />
                        {(eventData.instruction === "" &&
                            isSumbitClicked) &&
                            <p className="error">
                                Please enter instruction
                            </p>
                        }
                    </div>
                    <div className={classes.ticketRoot}>
                        {eventData?.tickets?.length > 0 &&
                            <h4>Tickets</h4>
                        }
                        {eventData?.tickets?.map((item, index) => (
                            <div className={classes.ticket} key={index}>
                                <button 
                                    className={classes.btnRemove} 
                                    onClick={() => removeTicket(index)}
                                >
                                    <RxCross2 />
                                </button>
                                <div className="d-flex justify-content-between">
                                    <div className={`mb-3 form-box ${classes.w48}`}>
                                        <label className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text" 
                                            className="form-control"
                                            id={`name_${index}`}
                                            name={`name_${index}`}
                                            value={item.name}
                                            onChange={(e) => handleTicketChange(e, index)}
                                            placeholder="Enter ticket name" />
                                        {(item.name === "" &&
                                            isSumbitClicked) &&
                                            <p className="error">
                                                Please enter ticket name
                                            </p>
                                        }
                                    </div>
                                    <div className={`mb-3 ms-2 me-2 form-box ${classes.w48}`}>
                                        <label className="form-label">
                                            Quantity
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id={`quantity_${index}`}
                                            name={`quantity_${index}`}
                                            value={item.quantity}
                                            onChange={(e) => handleTicketChange(e, index)}
                                            placeholder="Enter quantity" />
                                        {(item.name === "" &&
                                            isSumbitClicked) &&
                                            <p className="error">
                                                Please enter ticket name
                                            </p>
                                        }
                                    </div>
                                    <div className={`mb-3 form-box ${classes.w48}`}>
                                        <label className="form-label">
                                            Price
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id={`price_${index}`}
                                            name={`price_${index}`}
                                            value={item.price}
                                            onChange={(e) => handleTicketChange(e, index)}
                                            placeholder="Enter ticket price" />
                                        {((item.price === "" ||
                                            Number(item.price) < 0) &&
                                            isSumbitClicked) &&
                                            <p className="error">
                                                {item.price === "" && "Please enter ticket price"}
                                                {Number(item.price) < 0 && "Invalid ticket price"}
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3 form-box">
                                    <label className="form-label">
                                        Description
                                    </label>
                                    <input
                                        type="text" 
                                        className="form-control"
                                        id={`description_${index}`}
                                        name={`description_${index}`}
                                        value={item.description}
                                        onChange={(e) => handleTicketChange(e, index)}
                                        placeholder="Enter ticket description" />
                                    {(item.description === "" &&
                                        isSumbitClicked) &&
                                        <p className="error">
                                            Please enter ticket description
                                        </p>
                                    }
                                </div>
                            </div>
                        ))}
                        {(!from || from === "edit") &&
                            <button className="btn btn-outline-primary primary-button" onClick={handleAddTicket}>
                                Add Tickets +
                            </button>
                        }
                    </div>
                    {(!from || from === "edit") &&
                        <button
                            type="button"
                            disabled={isLoader}
                            onClick={handleSubmit}
                            className={`btn primary-button-2 ${isLoader && 'btn-disabled'}`}>
                            {isLoader ? <img src={Loader} alt="Loader" /> : (from === "edit" ? "Save Changes" : "Submit")}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default EventForm;